'use client'

import { useEffect, useRef } from 'react'
import Script from 'next/script'

import useWindowEvent from 'hooks/useWindowEvent'
import { getFingerprint } from 'libs/utils/fingerprint'
import useTracking from 'hooks/useTracking'
import useEnvs from 'hooks/useEnvs/useEnvs'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { dataDomeScriptActionEvent } from 'libs/common/event-tracker/events'
import { logWarning } from 'libs/utils/window'

const SCRIPT_SOURCE = {
  LATEST: 'https://dd.vinted.lt/tags.js',
  SANDBOX: 'https://static-assets.vinted.com/datadome/4.35.1/tags.js',
  PRODUCTION: 'https://static-assets.vinted.com/datadome/4.35.1/tags.js',
}

const DataDomeScript = () => {
  const { track } = useTracking()
  const blockedEndpoint = useRef('')

  const dataDomeClientSideKey = useEnvs('DATADOME_CLIENT_SIDE_KEY')
  const isDataDomeModuleEnabled = useFeatureSwitch('datadome_assessment')
  const isScriptTrackingEnabled = useFeatureSwitch('datadome_script_tracking')
  const isOverrideAbortFetchEnabled = useFeatureSwitch('datadome_web_override_abort_fetch')
  const isSandboxScriptSourceEnabled = useFeatureSwitch('datadome_sandbox_script_source')
  const isProductionScriptSourceEnabled = useFeatureSwitch('datadome_production_script_source')

  const getScriptSource = () => {
    if (isSandboxScriptSourceEnabled) return SCRIPT_SOURCE.SANDBOX
    if (isProductionScriptSourceEnabled) return SCRIPT_SOURCE.PRODUCTION

    return SCRIPT_SOURCE.LATEST
  }

  const datadomeOptions = {
    ajaxListenerPath: true,
    disableAutoRefreshOnCaptchaPassed: true,
    enableTagEvents: true,
    abortAsyncOnCaptchaDisplay: false,
    endpoint: 'https://dd.vinted.lt/js',
    overrideAbortFetch: isOverrideAbortFetchEnabled,
  }

  const getCommonTrackingData = async () => {
    return {
      fingerprint: await getFingerprint(),
      endpointName: blockedEndpoint.current,
    }
  }

  useWindowEvent('dd_blocked', async event => {
    if (!isScriptTrackingEnabled) return

    blockedEndpoint.current = event.detail.url

    const commonTrackingData = await getCommonTrackingData()

    track(dataDomeScriptActionEvent({ ...commonTrackingData, actionType: 'blocked' }))
  })

  useWindowEvent('dd_response_displayed', async event => {
    if (!isScriptTrackingEnabled) return

    const trackingData = await getCommonTrackingData()

    track(
      dataDomeScriptActionEvent({
        ...trackingData,
        actionType: 'response_displayed',
        responseType: event.detail.responseType,
      }),
    )
  })

  useWindowEvent('dd_response_passed', async event => {
    if (!isScriptTrackingEnabled) return

    const commonTrackingData = await getCommonTrackingData()

    track(
      dataDomeScriptActionEvent({
        ...commonTrackingData,
        actionType: 'response_passed',
        responseType: event.detail.responseType,
      }),
    )
  })

  useWindowEvent('dd_response_error', async () => {
    if (!isScriptTrackingEnabled) return

    const commonTrackingData = await getCommonTrackingData()

    track(dataDomeScriptActionEvent({ ...commonTrackingData, actionType: 'response_error' }))
  })

  useEffect(() => {
    if (!isDataDomeModuleEnabled || dataDomeClientSideKey) return

    logWarning('DataDome client-side key is not set')
  }, [dataDomeClientSideKey, isDataDomeModuleEnabled])

  if (!isDataDomeModuleEnabled) return null
  if (!dataDomeClientSideKey) return null

  return (
    <Script id="data-dome-script" strategy="afterInteractive">
      {`!function(a,b,c,d,e,f){a.ddjskey=e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","${getScriptSource()}","${dataDomeClientSideKey}", ${JSON.stringify(
        datadomeOptions,
      )});`}
    </Script>
  )
}

export default DataDomeScript
