import { Sagas } from './types'

import catalogFilterSagas from './catalog-filters/sagas'
import mediaUpload from './media-upload/sagas'
import conversationSagas from './conversation/sagas'
import profileSagas from './profile/sagas'
import { sagas as checkoutSagas } from './checkout'
import itemsSagas from './items/sagas'
import locationSagas from './location/sagas'
import itemUploadSagas from './item-upload/sagas'
import bannersSagas from './banners/sagas'
import extraServiceSagas from './extra-service/sagas'
import packageSizesSagas from './package-sizes/sagas'
import profileSettingsSagas from './profile-settings/sagas'
import businessAddressesSagas from './business-addresses/sagas'
import systemConfigurationSagas from './system-configuration/sagas'
import returnShipmentSagas from './return-shipment/sagas'
import adsSagas from './ads/sagas'
import dropOffPointMapSagas from './drop-off-point-map/sagas'

export const getStaticSagas = (): Sagas => []

export const getCoreSagas = (): Sagas => [
  catalogFilterSagas(),
  mediaUpload(),
  conversationSagas(),
  checkoutSagas(),
  itemsSagas(),
  locationSagas(),
  itemUploadSagas(),
  bannersSagas(),
  extraServiceSagas(),
  packageSizesSagas(),
  profileSettingsSagas(),
  businessAddressesSagas(),
  systemConfigurationSagas(),
  returnShipmentSagas(),
  adsSagas(),
  profileSagas(),
  dropOffPointMapSagas(),
]

export { default as businessAccountsSaga } from './business-accounts/sagas'
