import { isObject } from 'lodash'

import {
  ColorDto,
  CatalogDto,
  ItemPriceSuggestionsDto,
  ItemUploadDto,
  AssignedPhotoDto,
  CatalogPhotoDto,
  ItemAttributeDto,
  ItemUploadConditionDto,
  UploadFormModelDto,
  CatalogBadgeDto,
} from 'types/dtos'
import { BookDetailsModel, ItemPriceSuggestionsModel } from 'types/models'
import { BookDetailsResp, ItemSuggestionsResponse, GetSimilarItemsResp } from 'types/api'
import { BadgeModifiedTheme } from 'types/ui'

import { transformBrandDtos } from 'data/transformers/brand'
import { transformColorDtos } from 'data/transformers/color'
import { transformSizeDtos } from 'data/transformers/size'
import { transformItemDtosToProductItems } from 'data/transformers/product-item'

import { MODEL_OTHER_ID } from 'pages/ItemUpload/ModelSelect/constants'

import {
  ItemAttributes,
  ConditionSelectType,
  ColorSelectType,
  CatalogSelectType,
  DynamicAttribute,
} from './types'

export const transformConditions = ({
  conditions,
}: {
  conditions: Array<ItemUploadConditionDto>
}): Array<ConditionSelectType> =>
  conditions.map(({ id, title, explanation }) => ({
    id,
    title,
    body: explanation,
  }))

export const transformColors = (colorsDto: Array<ColorDto>): Array<ColorSelectType> =>
  colorsDto.map(({ id, title, code }) => ({
    id,
    title,
    code,
  }))

export const transformDynamicAttributes = (
  attributes: Array<ItemAttributeDto> | undefined,
): Array<DynamicAttribute> =>
  attributes?.map(({ code, ids }) => ({ field: code, value: ids })) || []

function isCatalogPhotoDto(value: Record<string, unknown>): value is CatalogPhotoDto {
  return 'url' in value
}

export const transformCatalogBadge = (
  badge: CatalogBadgeDto | null | undefined,
): CatalogBadgeDto | null => {
  if (!badge) return null

  const supportedThemes: Array<BadgeModifiedTheme> = [
    'primary',
    'muted',
    'success',
    'warning',
    'expose',
    'amplified',
  ]

  if (!supportedThemes.includes(badge.theme)) return null

  return badge
}

export const transformCatalogs = (catalogsDto: Array<CatalogDto>): Array<CatalogSelectType> => {
  const transformedCatalogs: Array<CatalogSelectType> = []

  function catalogTransform(catalog: CatalogDto, parentId: number) {
    const catalogIds: Array<number> = []

    if (catalog.catalogs) {
      catalog.catalogs.forEach(child => {
        catalogTransform(child, catalog.id)
        catalogIds.push(child.id)
      })
    }

    const transformedCatalog: CatalogSelectType = {
      id: catalog.id,
      code: catalog.code,
      parentId,
      catalogIds,
      url: catalog.url,
      title: catalog.title,
      restrictedToStatusId: catalog.restricted_to_status_id,
      isColorSelectShown: !!catalog.color_field_visibility,
      isSizeSelectShown: !!catalog.size_field_visibility,
      isBrandSelectShown: !!catalog.brand_field_visibility,
      isIsbnInputShown: !!catalog.isbn_field_visibility,
      isAuthorInputShown: !!catalog.author_field_visibility,
      isBookTitleInputShown: !!catalog.book_title_field_visibility,
      isMeasurementsSelectShown: !!catalog.measurements_field_visibility,
      sizeGroupId: catalog.size_group_id,
      unisexCatalogId: catalog.unisex_catalog_id,
      isVideoGameRatingSelectShown: !!catalog.video_game_rating_field_visibility,
      photo: isCatalogPhotoDto(catalog.photo) ? catalog.photo : null,
      badge: catalog.badge || null,
    }

    transformedCatalogs.push(transformedCatalog)
  }

  catalogsDto.forEach(catalog => catalogTransform(catalog, 0))

  return transformedCatalogs
}

export const transformSimilarItems = ({ items }: GetSimilarItemsResp) =>
  transformItemDtosToProductItems(items)

export const transformPriceSuggestionsDto = (
  { price_suggestion, similar_sold_items_present }: ItemPriceSuggestionsDto,
  isPriceSuggestionsMoneyObjectHeaderEnabled: boolean,
): ItemPriceSuggestionsModel => {
  const min =
    isPriceSuggestionsMoneyObjectHeaderEnabled && isObject(price_suggestion?.minimum)
      ? price_suggestion?.minimum?.amount
      : price_suggestion?.minimum
  const max =
    isPriceSuggestionsMoneyObjectHeaderEnabled && isObject(price_suggestion?.maximum)
      ? price_suggestion?.maximum?.amount
      : price_suggestion?.maximum

  return {
    min: price_suggestion ? Number(min) : null,
    max: price_suggestion ? Number(max) : null,
    currency: price_suggestion?.currency || null,
    hasSimilarItems: similar_sold_items_present,
  }
}

export const transformBookDetails = ({ book_details }: BookDetailsResp): BookDetailsModel => ({
  author: book_details.author,
  title: book_details.title,
})

const transformUploadFormModel = (modelData: Omit<UploadFormModelDto, 'id' | 'children'>) => ({
  model: {
    ...(modelData.metadata.collection_id === MODEL_OTHER_ID && { suggestion: modelData.name }),
    metadata: modelData.metadata,
  },
})

export const transformItemAttributesToItemUploadDto = (
  {
    id,
    catalogId,
    colorIds,
    title,
    description,
    isUnisex,
    statusId,
    price,
    sizeId,
    brandId,
    brandTitle,
    isbn,
    packageSize,
    videoGameRatingId,
    measurementLength,
    measurementWidth,
    manufacturer,
    manufacturerLabel,
    modelData,
  }: ItemAttributes,
  assignedPhotos: Array<AssignedPhotoDto>,
  dynamicAttributes: Array<DynamicAttribute>,
  currency: string,
  tempUuid = '',
): ItemUploadDto => ({
  id,
  currency,
  temp_uuid: tempUuid,
  title,
  description,
  brand_id: brandId,
  brand: brandTitle,
  size_id: sizeId,
  catalog_id: catalogId,
  isbn,
  is_unisex: isUnisex,
  status_id: statusId,
  video_game_rating_id: videoGameRatingId,
  price,
  package_size_id: packageSize.id,
  shipment_prices: {
    domestic: packageSize.custom.domestic,
    international: packageSize.custom.international,
  },
  color_ids: colorIds,
  assigned_photos: assignedPhotos,
  measurement_length: measurementLength,
  measurement_width: measurementWidth,
  item_attributes: dynamicAttributes?.map(attr => ({
    code: attr.field,
    ids: attr.value,
  })),
  manufacturer,
  manufacturer_labelling: manufacturerLabel,
  ...(modelData && transformUploadFormModel(modelData)),
})

export const transformItemSuggestions = ({
  brands,
  colors,
  sizes,
  catalogs,
}: ItemSuggestionsResponse) => ({
  catalogs,
  brands: transformBrandDtos(brands),
  colors: transformColorDtos(colors),
  sizes: transformSizeDtos(sizes),
})
