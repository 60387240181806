import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'

import {
  CheckoutModel,
  EscrowPaymentModel,
  PaymentAuthActionModel,
  CheckoutConfigurationModel,
  PayrailsInitDataModel,
} from 'types/models'

import { ErrorItem, UpdateCheckoutDataArgs } from 'types/api'

import { UiState as UiStateEnum } from 'constants/ui'

import { stateName, FieldName } from './constants'
import { State, FormState, UiState, ErrorUiState, CvvHandlingData } from './types'

export const initialFormState: FormState = {
  errors: {
    paymentError: null,
    isKycConfirmationNeeded: false,
    hasChecksumError: false,
    hasShippingDiscountError: false,
    isPaymentFailedAfterRedirect: false,
    isPaymentProcessingTakesTooLong: false,
    cvvHandlingError: null,
    shippingContactErrors: [],
  },
}

export const initialErrorUiState: ErrorUiState = {
  byName: {},
  names: [],
}

export const initialUiState: UiState = {
  errors: initialErrorUiState,
  checkoutData: UiStateEnum.Idle,
}

export const initialCvvHandlingData: CvvHandlingData = {
  isCvvHandlingFlowEnabled: false,
  encryptedCvv: null,
  payrailsInitData: null,
}

export const initialState: State = {
  ui: initialUiState,
  formState: initialFormState,
  payment: null,
  isPaymentProcessing: false,
  checkoutData: null,
  configuration: null,
  blikPaymentAuth: null,
  paymentAuthAction: null,
  twoFactorVerification: null,
  cvvHandlingData: initialCvvHandlingData,
}

const setFieldError: CaseReducer<State, PayloadAction<{ fieldError: ErrorItem }>> = (
  draft,
  action,
) => {
  const { fieldError } = action.payload

  draft.ui.errors.byName[fieldError.field] = fieldError.value

  if (draft.ui.errors.names.includes(fieldError.field)) return

  draft.ui.errors.names.push(fieldError.field)
}

const setFieldErrors: CaseReducer<State, PayloadAction<{ fieldErrors: Array<ErrorItem> }>> = (
  draft,
  action,
) => {
  const { fieldErrors } = action.payload

  fieldErrors.forEach(error => {
    draft.ui.errors.byName[error.field] = error.value
  })

  draft.ui.errors.names = fieldErrors.map(error => error.field)
}

const removeFieldError: CaseReducer<State, PayloadAction<{ fieldName: FieldName }>> = (
  draft,
  action,
) => {
  const { fieldName } = action.payload

  if (draft.ui.errors.byName[fieldName]) {
    delete draft.ui.errors.byName[fieldName]
  }

  remove(draft.ui.errors.names, name => name === fieldName)
}

const removeFieldsErrors: CaseReducer<State> = draft => {
  draft.ui.errors.byName = {}
  draft.ui.errors.names = []
}

const getCheckoutDataRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.ui.checkoutData = UiStateEnum.Pending
}

const getCheckoutDataSuccess: CaseReducer<State, PayloadAction<{ checkout: CheckoutModel }>> = (
  draft,
  action,
) => {
  draft.checkoutData = action.payload.checkout
  draft.ui.checkoutData = UiStateEnum.Success
}

const getCheckoutDataFailure: CaseReducer<State> = draft => {
  draft.isPaymentProcessing = false
  draft.ui.checkoutData = UiStateEnum.Failure
}

const updateCheckoutDataRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; updatedCheckoutData?: UpdateCheckoutDataArgs }>
> = draft => {
  draft.ui.checkoutData = UiStateEnum.Pending
}

const updateCheckoutDataSuccess: CaseReducer<State, PayloadAction<{ checkout: CheckoutModel }>> = (
  draft,
  action,
) => {
  draft.checkoutData = action.payload.checkout
  draft.ui.checkoutData = UiStateEnum.Success
}

const updateCheckoutDataFailure: CaseReducer<State> = draft => {
  draft.isPaymentProcessing = false
  draft.ui.checkoutData = UiStateEnum.Failure
}

const setTwoFactorVerification: CaseReducer<
  State,
  PayloadAction<{
    id: number
    askUser2fa: boolean
    showResendOption: boolean
    userMaskedPhoneNumber?: string
    nextResendAvailableIn: number
  }>
> = (draft, action) => {
  draft.twoFactorVerification = action.payload
}

const clearTwoFactorVerification: CaseReducer<State> = draft => {
  draft.twoFactorVerification = null
}

const setIsPaymentProcessing: CaseReducer<
  State,
  PayloadAction<{ isPaymentProcessing: boolean }>
> = (draft, action) => {
  draft.isPaymentProcessing = action.payload.isPaymentProcessing
}

const setPayment: CaseReducer<State, PayloadAction<EscrowPaymentModel>> = (draft, action) => {
  draft.payment = action.payload
}

const setPaymentError: CaseReducer<
  State,
  PayloadAction<{ error: { message: string | undefined } }>
> = (draft, action) => {
  draft.formState.errors.paymentError = action.payload.error
}

const clearPaymentError: CaseReducer<State> = draft => {
  draft.formState.errors.paymentError = null
}

const setPaymentAuthAction: CaseReducer<
  State,
  PayloadAction<{ paymentAuthAction: PaymentAuthActionModel }>
> = (draft, action) => {
  draft.paymentAuthAction = action.payload.paymentAuthAction
}

const clearPaymentAuthAction: CaseReducer<State> = draft => {
  draft.paymentAuthAction = null
}

const setPaymentAuthActionError: CaseReducer<State, PayloadAction<{ message: string }>> = (
  draft,
  action,
) => {
  const { message } = action.payload

  draft.formState.errors.paymentError = { message }
  draft.paymentAuthAction = null
  draft.isPaymentProcessing = false
}

const cancelPaymentAuth: CaseReducer<State> = draft => {
  draft.paymentAuthAction = null
  draft.isPaymentProcessing = false
}

const setIsKycConfirmationNeeded: CaseReducer<State, PayloadAction<{ isNeeded: boolean }>> = (
  draft,
  action,
) => {
  const { isNeeded } = action.payload

  draft.formState.errors.isKycConfirmationNeeded = isNeeded
}

const fetchConfigurationSuccess: CaseReducer<
  State,
  PayloadAction<{
    configuration: CheckoutConfigurationModel
  }>
> = (draft, action) => {
  draft.configuration = action.payload.configuration
}

const fetchConfigurationFailure: CaseReducer<State> = draft => {
  draft.configuration = null
}

const setHasChecksumError: CaseReducer<State, PayloadAction<{ hasChecksumError: boolean }>> = (
  draft,
  action,
) => {
  const { hasChecksumError } = action.payload

  draft.formState.errors.hasChecksumError = hasChecksumError
}

const setHasShippingDiscountError: CaseReducer<
  State,
  PayloadAction<{ hasShippingDiscountError: boolean }>
> = (draft, action) => {
  const { hasShippingDiscountError } = action.payload

  draft.formState.errors.hasShippingDiscountError = hasShippingDiscountError
}

const setIsPaymentProcessingTakesTooLong: CaseReducer<
  State,
  PayloadAction<{ isPaymentProcessingTakesTooLong: boolean }>
> = (draft, action) => {
  const { isPaymentProcessingTakesTooLong } = action.payload

  draft.formState.errors.isPaymentProcessingTakesTooLong = isPaymentProcessingTakesTooLong
}

const setIsPaymentFailedAfterRedirect: CaseReducer<
  State,
  PayloadAction<{ isPaymentFailedAfterRedirect: boolean }>
> = (draft, action) => {
  const { isPaymentFailedAfterRedirect } = action.payload

  draft.formState.errors.isPaymentFailedAfterRedirect = isPaymentFailedAfterRedirect
}

const clearIsPaymentFailedAfterRedirect: CaseReducer<State> = draft => {
  draft.formState.errors.isPaymentFailedAfterRedirect = false
}

const confirmBlikAuth: CaseReducer<State, PayloadAction<{ blikCode: string }>> = (
  draft,
  action,
) => {
  const { blikCode } = action.payload

  draft.blikPaymentAuth = { blikCode }
}

const clearBlikAuth: CaseReducer<State> = draft => {
  draft.blikPaymentAuth = null
}

const setIsCvvHandlingFlowEnabled: CaseReducer<
  State,
  PayloadAction<{ isCvvHandlingFlowEnabled: boolean }>
> = (draft, action) => {
  draft.cvvHandlingData.isCvvHandlingFlowEnabled = action.payload.isCvvHandlingFlowEnabled
}

const clearIsCvvHandlingFlowEnabled: CaseReducer<State> = draft => {
  draft.cvvHandlingData.isCvvHandlingFlowEnabled = false
}

const setCvvHandlingError: CaseReducer<
  State,
  PayloadAction<{ error: { message: string | undefined } }>
> = (draft, action) => {
  draft.formState.errors.cvvHandlingError = action.payload.error
}

const clearCvvHandlingError: CaseReducer<State> = draft => {
  draft.formState.errors.cvvHandlingError = null
}

const setPayrailsInitData: CaseReducer<
  State,
  PayloadAction<{ payrailsInitData: PayrailsInitDataModel }>
> = (draft, action) => {
  draft.cvvHandlingData.payrailsInitData = action.payload.payrailsInitData
}

const setEncryptedCvv: CaseReducer<State, PayloadAction<{ encryptedCvv: string | null }>> = (
  draft,
  action,
) => {
  draft.cvvHandlingData.encryptedCvv = action.payload.encryptedCvv
}

const setShippingContactNumber = (
  draft: State,
  action: PayloadAction<{ phoneNumber: string | null }>,
) => {
  draft.checkoutData!.buyer.phoneNumber = action.payload.phoneNumber
}

const setShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.formState.errors.shippingContactErrors.push(error)
}

const removeShippingContactErrors: CaseReducer<State> = draft => {
  draft.formState.errors.shippingContactErrors = []
}

const removeShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.formState.errors.shippingContactErrors =
    draft.formState.errors.shippingContactErrors.filter(
      ({ field, value }) => field !== error.field && value !== error.value,
    )
}

const checkoutSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setFieldError,
    setFieldErrors,
    removeFieldError,
    removeFieldsErrors,
    getCheckoutDataRequest,
    getCheckoutDataSuccess,
    getCheckoutDataFailure,
    updateCheckoutDataRequest,
    updateCheckoutDataSuccess,
    updateCheckoutDataFailure,
    setTwoFactorVerification,
    clearTwoFactorVerification,
    setIsPaymentProcessing,
    setPayment,
    setPaymentError,
    clearPaymentError,
    setHasChecksumError,
    setHasShippingDiscountError,
    setIsKycConfirmationNeeded,
    setIsPaymentFailedAfterRedirect,
    clearIsPaymentFailedAfterRedirect,
    setIsPaymentProcessingTakesTooLong,
    clearBlikAuth,
    confirmBlikAuth,
    cancelPaymentAuth,
    setPaymentAuthAction,
    clearPaymentAuthAction,
    setPaymentAuthActionError,
    fetchConfigurationSuccess,
    fetchConfigurationFailure,
    setIsCvvHandlingFlowEnabled,
    clearIsCvvHandlingFlowEnabled,
    setPayrailsInitData,
    setEncryptedCvv,
    setCvvHandlingError,
    clearCvvHandlingError,
    setShippingContactError,
    setShippingContactNumber,
    removeShippingContactErrors,
    removeShippingContactError,
  },
})

export const { actions } = checkoutSlice
export const plug = { [stateName]: checkoutSlice.reducer }
export default checkoutSlice.reducer
