import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { AbTestVariant } from 'constants/abtest'
import { GenerateLabelModal, LabelType, ConversationInitiatorType } from 'constants/conversation'
import { HttpStatus } from 'data/api/response-codes'
import { ErrorItem } from 'types/api'

import {
  CurrencyAmountModel,
  OfferRequestOptionsModel,
  ReturnShippingOptionModel,
  TransactionModel,
  ReactionModel,
  CollectionDatesModel,
  CollectionDateModel,
  LabelOptionsModel,
  ShippingOrderPackageSizesModel,
} from 'types/models'

import {
  State,
  Conversation,
  ComplaintResolution,
  ShipmentInstructions,
  Message,
  OfferPriceSuggestionOption,
} from './types'
import { stateName } from './constants'

export const initialState: State = {
  conversation: undefined,
  shipmentInstructions: undefined,
  recipientId: null,
  activeModal: GenerateLabelModal.None,
  ui: {
    isSidebarOpen: false,
    sendMessage: { error: null, uiState: UiState.Idle },
    enterTrackingCode: { error: null, uiState: UiState.Idle },
    createOffer: { error: null, uiState: UiState.Idle },
    getTransaction: { error: null, uiState: UiState.Idle },
    acceptOffer: { error: null, uiState: UiState.Idle },
    rejectOffer: { error: null, uiState: UiState.Idle },
    offerRequestOptions: { error: null, uiState: UiState.Idle },
    getConversation: { error: null, uiState: UiState.Idle, status: null },
    selectDropOffType: {
      selectedDropOffTypeKey: null,
      isSubmitEnabled: false,
      uiState: UiState.Idle,
      error: null,
    },
    harassmentWarning: {
      isModalOpen: false,
      wasModalOpen: false,
      scoredMessage: null,
      scoredHarassmentMessageCorrelationId: null,
    },
    selectLabelType: {
      selectedLabelType: null,
      isSubmitEnabled: false,
    },
    isEmailSharingModalVisible: false,
    shipmentInstructions: {
      error: null,
      uiState: UiState.Idle,
    },
    collectionDates: {
      selectedCollectionDate: null,
      selectedCollectionTime: null,
      error: null,
      uiState: UiState.Idle,
    },
    labelOptions: {
      error: null,
      uiState: UiState.Idle,
    },
    isFirstTimeListerEducationModalVisible: false,
    shippingOrderPackageSizes: {
      selectedPackageSizeId: null,
      submittedSelectedPackageSizeId: null,
      isPackageSizeValidationVisible: false,
      error: null,
      uiState: UiState.Idle,
    },
  },
  transaction: null,
  collectionDates: undefined,
  selectedOfferPriceSuggestionOption: {
    isCustom: false,
  },
  offerRequestSessionId: undefined,
  labelOptions: undefined,
  shippingOrderPackageSizes: undefined,
  shippingContactErrors: [],
}

const getConversationSuccess: CaseReducer<
  State,
  PayloadAction<{ conversation: Conversation; status: HttpStatus }>
> = (draft, action) => {
  const { conversation, status } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.status = status
}

const getConversationUiPending: CaseReducer<State, PayloadAction<{ fromPostReply?: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.getConversation.uiState = UiState.Pending
  draft.ui.getConversation.status = null

  if (!action.payload.fromPostReply) {
    draft.conversation = undefined
  }
}

const getConversationUiFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null; status: HttpStatus | null }>
> = (draft, action) => {
  const { error, status } = action.payload

  draft.conversation = undefined
  draft.ui.getConversation.error = error
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.status = status
}

const clearConversation: CaseReducer<State> = draft => {
  draft.conversation = undefined
  draft.ui.getConversation.status = null
  draft.ui.getConversation.uiState = UiState.Idle
  draft.ui.getConversation.error = null
}

const setConversation: CaseReducer<State, PayloadAction<{ conversation: Conversation }>> = (
  draft,
  action,
) => {
  const { conversation } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.status = HttpStatus.Ok
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.error = null
}

const getComplaintSuccess: CaseReducer<State, PayloadAction<ComplaintResolution>> = (
  draft,
  action,
) => {
  if (draft.conversation) {
    draft.conversation.complaintResolution = action.payload
  }
}

const setIsSidebarOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isSidebarOpen = action.payload.isOpen
}

const getShipmentInstructionsRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.ui.shipmentInstructions.uiState = UiState.Pending
}

const getShipmentInstructionsSuccess: CaseReducer<State, PayloadAction<ShipmentInstructions>> = (
  draft,
  action,
) => {
  draft.ui.shipmentInstructions.uiState = UiState.Success
  draft.shipmentInstructions = action.payload
}

const getShipmentInstructionsFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.shipmentInstructions.uiState = UiState.Failure
  draft.ui.shipmentInstructions.error = error
}

const clearShipmentInstructions: CaseReducer<State> = draft => {
  draft.shipmentInstructions = undefined
}

const getReturnShipmentOptionSuccess: CaseReducer<
  State,
  PayloadAction<ReturnShippingOptionModel>
> = (draft, action) => {
  draft.returnShippingOption = action.payload
}

const postReplyRequest: CaseReducer<
  State,
  PayloadAction<{ value: string | null | undefined; photoTempUuids?: Array<string> | null }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const postReplySuccess: CaseReducer<State> = draft => {
  draft.ui.sendMessage.uiState = UiState.Success
  draft.ui.sendMessage.error = null
}

const setSendMessageUiFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.sendMessage.error = error
  draft.ui.sendMessage.uiState = UiState.Failure
}

const createItemConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    itemId: string
    sellerId: string
    initiator: ConversationInitiatorType
  }>
> = draft => {
  draft.ui.getConversation.uiState = UiState.Pending
}

const createItemConversationThreadFailure: CaseReducer<
  State,
  PayloadAction<{
    error: string | null
  }>
> = (draft, action) => {
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.error = action.payload.error
}

const createItemConversationThreadSuccess: CaseReducer<
  State,
  PayloadAction<{
    conversation: Conversation
  }>
> = (draft, actions) => {
  draft.conversation = actions.payload.conversation
  draft.ui.getConversation.uiState = UiState.Success
}

const createConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    recipientId: number | null | undefined
    value: string | null | undefined
    photoTempUuids?: Array<string> | null
  }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const enterTrackingCodeRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; trackingCode: string }>
> = draft => {
  draft.ui.enterTrackingCode.uiState = UiState.Pending
}

const enterTrackingCodeSuccess: CaseReducer<State> = draft => {
  draft.ui.enterTrackingCode.uiState = UiState.Success
  draft.ui.enterTrackingCode.error = null
}

const enterTrackingCodeUiFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.enterTrackingCode.uiState = UiState.Failure
  draft.ui.enterTrackingCode.error = error
}

const deleteEnterTrackingCodeError: CaseReducer<State> = draft => {
  draft.ui.enterTrackingCode.error = null
}

const setRecipientId: CaseReducer<State, PayloadAction<{ recipientId: number | null }>> = (
  draft,
  action,
) => {
  draft.recipientId = action.payload.recipientId
}

const setSelectedDropOffTypeKey: CaseReducer<
  State,
  PayloadAction<{ dropOffTypeKey: number | string | null }>
> = (draft, action) => {
  draft.ui.selectDropOffType.selectedDropOffTypeKey = action.payload.dropOffTypeKey
  draft.ui.selectDropOffType.isSubmitEnabled = true
}

const clearDropOffTypeSelection: CaseReducer<State> = draft => {
  draft.ui.selectDropOffType.isSubmitEnabled = false
  draft.ui.selectDropOffType.selectedDropOffTypeKey = null
}

const deleteDropOffTypeSelectionError: CaseReducer<State> = draft => {
  draft.ui.selectDropOffType.error = null
}

const createOfferRequest: CaseReducer<
  State,
  PayloadAction<{
    offerPrice: string
    currentPrice: string
    isBuyer: boolean
    currency: string
    transactionId: number
    offerSuggestionOptionIndex?: number
  }>
> = draft => {
  draft.ui.createOffer.uiState = UiState.Pending
}

const createOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.createOffer.uiState = UiState.Success
  draft.ui.createOffer.error = null
}

const createOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.createOffer.uiState = UiState.Failure
  draft.ui.createOffer.error = error
}

const acceptOfferRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; offerRequestId: number }>
> = draft => {
  draft.ui.acceptOffer.uiState = UiState.Pending
}

const acceptOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.acceptOffer.uiState = UiState.Success
  draft.ui.acceptOffer.error = null
}

const acceptOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.acceptOffer.uiState = UiState.Failure
  draft.ui.acceptOffer.error = error
}

const rejectOfferRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; offerRequestId: number }>
> = draft => {
  draft.ui.rejectOffer.uiState = UiState.Pending
}

const rejectOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.rejectOffer.uiState = UiState.Success
  draft.ui.rejectOffer.error = null
}

const rejectOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.rejectOffer.uiState = UiState.Failure
  draft.ui.rejectOffer.error = error
}

const setMessageDeleted: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => (message as Message).id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.url = ''
    photo.isHidden = true
  } else {
    message.isHidden = true
    message.content = ''
  }
}

const setMessageLiked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reaction: ReactionModel }>
> = (draft, action) => {
  const { messageId, photoId, reaction } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = {
      id: reaction.id,
      type: reaction.type,
    }

    return
  }

  message.reaction = {
    id: reaction.id,
    type: reaction.type,
  }
}

const setMessageUnliked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reactionId: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = null

    return
  }

  message.reaction = null
}

const setActiveGenerateLabelModal: CaseReducer<
  State,
  PayloadAction<{
    modal: GenerateLabelModal
  }>
> = (draft, action) => {
  const { modal } = action.payload

  draft.activeModal = modal
}

const setHarassmentMessage: CaseReducer<
  State,
  PayloadAction<{ messageText: string | null; correlationId: string | null }>
> = (draft, action) => {
  const { messageText, correlationId } = action.payload

  draft.ui.sendMessage.uiState = UiState.Failure
  draft.ui.harassmentWarning.isModalOpen = true
  draft.ui.harassmentWarning.wasModalOpen = true
  draft.ui.harassmentWarning.scoredMessage = messageText
  draft.ui.harassmentWarning.scoredHarassmentMessageCorrelationId = correlationId
}

const clearHarassmentMessage: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
  draft.ui.harassmentWarning.wasModalOpen = false
  draft.ui.harassmentWarning.scoredMessage = null
}

const setHarassmentWarningModalClosed: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
}

const setEmailSharingModalVisible: CaseReducer<State, PayloadAction<{ isVisible: boolean }>> = (
  draft,
  action,
) => {
  const { isVisible } = action.payload

  draft.ui.isEmailSharingModalVisible = isVisible
}

const setSelectedLabelType: CaseReducer<State, PayloadAction<{ labelType: LabelType | null }>> = (
  draft,
  action,
) => {
  draft.ui.selectLabelType.selectedLabelType = action.payload.labelType
  draft.ui.selectLabelType.isSubmitEnabled = true
}

const clearDigitalLabelTypeSelection: CaseReducer<State> = draft => {
  draft.ui.selectLabelType.isSubmitEnabled = false
  draft.ui.selectLabelType.selectedLabelType = null
}

const getOfferRequestOptionsRequest: CaseReducer<
  State,
  PayloadAction<{ itemPrice: CurrencyAmountModel }>
> = draft => {
  draft.ui.offerRequestOptions.uiState = UiState.Pending
}

const getOfferRequestOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{ offerRequestOptions: OfferRequestOptionsModel; abTestVariant?: string }>
> = (draft, action) => {
  draft.offerRequestOptions = action.payload.offerRequestOptions

  draft.ui.offerRequestOptions.uiState = UiState.Success
  draft.ui.offerRequestOptions.error = null

  switch (action.payload.abTestVariant) {
    case AbTestVariant.A:
      draft.selectedOfferPriceSuggestionOption = {
        isCustom: false,
        suggestion: action.payload.offerRequestOptions.offerSuggestions[0],
      }
      break
    case AbTestVariant.B:
      draft.selectedOfferPriceSuggestionOption = {
        isCustom: true,
      }
      break
    default:
      draft.selectedOfferPriceSuggestionOption = undefined
  }
}

const getOfferRequestOptionsFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  draft.ui.offerRequestOptions.uiState = UiState.Failure
  draft.ui.offerRequestOptions.error = action.payload.error
}

const fetchTransactionRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: number
  }>
> = draft => {
  draft.ui.getTransaction.uiState = UiState.Pending
}

const fetchTransactionRequestSuccess: CaseReducer<
  State,
  PayloadAction<{ transaction: TransactionModel }>
> = (draft, action) => {
  const { transaction } = action.payload

  draft.transaction = transaction

  draft.ui.getTransaction.uiState = UiState.Success
  draft.ui.getTransaction.error = null
}

const fetchTransactionRequestFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.getTransaction.uiState = UiState.Failure
  draft.ui.getTransaction.error = error
}

const setFirstTimeListerEducationModalVisible: CaseReducer<
  State,
  PayloadAction<{ isVisible: boolean }>
> = (draft, action) => {
  const { isVisible } = action.payload

  draft.ui.isFirstTimeListerEducationModalVisible = isVisible
}

const getCollectionDatesRequest: CaseReducer<
  State,
  PayloadAction<{
    shipmentId: number
  }>
> = draft => {
  draft.ui.collectionDates.uiState = UiState.Pending
}

const getCollectionDatesSuccess: CaseReducer<
  State,
  PayloadAction<{ collectionDates: CollectionDatesModel }>
> = (draft, action) => {
  const { collectionDates } = action.payload

  if (collectionDates.dates.length === 0) {
    draft.collectionDates = collectionDates
    draft.ui.collectionDates.uiState = UiState.Success
    draft.ui.collectionDates.error = null

    return
  }

  const uniqueDatesObj: Record<string, CollectionDateModel> = collectionDates.dates.reduce(
    (uniqueDatesMap, { collection, collectionTo, dropOffTo }) => {
      const dateKey = collection?.split(' ')[0]
      if (dateKey && !uniqueDatesMap[dateKey]) {
        return {
          ...uniqueDatesMap,
          [dateKey]: { collection, collectionTo, dropOffTo },
        }
      }

      return uniqueDatesMap
    },
    {} as Record<string, CollectionDateModel>,
  )

  const uniqueDates = Object.values(uniqueDatesObj)
  draft.ui.collectionDates.availableCollectionDates = uniqueDates

  const firstDate = collectionDates.dates[0]

  const firstDateCollection = collectionDates?.dates[0]?.collection?.split('T')[0]

  if (!firstDateCollection) return

  const sameDayCollectionTimes = action.payload.collectionDates.dates.filter(date =>
    date.collection?.includes(firstDateCollection),
  )

  const firstSelectedTime = sameDayCollectionTimes[0]

  draft.collectionDates = collectionDates
  draft.ui.collectionDates.selectedCollectionDate = firstDate
  draft.ui.collectionDates.availableCollectionTimes = sameDayCollectionTimes
  draft.ui.collectionDates.selectedCollectionTime = firstSelectedTime
  draft.ui.collectionDates.uiState = UiState.Success
  draft.ui.collectionDates.error = null
}

const getCollectionDatesFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.collectionDates.uiState = UiState.Failure
  draft.ui.collectionDates.error = error
}

const setSelectedCollectionDate: CaseReducer<
  State,
  PayloadAction<{ collectionDate: CollectionDateModel }>
> = (draft, action) => {
  const dateString = action.payload.collectionDate?.collection?.split('T')[0]

  if (!dateString) return

  const filteredDates = draft.collectionDates?.dates?.filter(date =>
    date.collection?.includes(dateString),
  )

  draft.ui.collectionDates.selectedCollectionDate = action.payload.collectionDate

  if (!filteredDates) return

  const firstAvailableTime = filteredDates[0]

  draft.ui.collectionDates.availableCollectionTimes = filteredDates
  draft.ui.collectionDates.selectedCollectionTime = firstAvailableTime
}

const setSelectedCollectionTime: CaseReducer<
  State,
  PayloadAction<{ collectionTime: CollectionDateModel }>
> = (draft, action) => {
  draft.ui.collectionDates.selectedCollectionTime = action.payload.collectionTime
}

const clearSelectedCollection: CaseReducer<State, PayloadAction> = draft => {
  draft.collectionDates = undefined
  draft.selectedCollectionDate = undefined
  draft.selectedCollectionTime = undefined
  draft.ui.collectionDates.availableCollectionDates = undefined
  draft.ui.collectionDates.availableCollectionTimes = undefined
  draft.ui.collectionDates.selectedCollectionDate = null
  draft.ui.collectionDates.selectedCollectionTime = null
}

const setSelectedOfferPriceSuggestionOption: CaseReducer<
  State,
  PayloadAction<{ offerPriceSuggestionOption: OfferPriceSuggestionOption }>
> = (draft, action) => {
  draft.selectedOfferPriceSuggestionOption = action.payload.offerPriceSuggestionOption
}

const resetSelectedOfferPriceSuggestionOption: CaseReducer<
  State,
  PayloadAction<{ abTestVariant?: string }>
> = (draft, action) => {
  switch (action.payload.abTestVariant) {
    case AbTestVariant.A:
      draft.selectedOfferPriceSuggestionOption = {
        isCustom: false,
        suggestion: draft.offerRequestOptions?.offerSuggestions[0],
      }
      break
    case AbTestVariant.B:
      draft.selectedOfferPriceSuggestionOption = {
        isCustom: true,
      }
      break
    default:
      draft.selectedOfferPriceSuggestionOption = undefined
  }
}

const setOfferRequestSessionId: CaseReducer<
  State,
  PayloadAction<{ offerRequestSessionId: string }>
> = (draft, action) => {
  draft.offerRequestSessionId = action.payload.offerRequestSessionId
}

const confirmCollectionDateSelection: CaseReducer<
  State,
  PayloadAction<{
    collectionDate: CollectionDateModel
    collectionTime: CollectionDateModel
  }>
> = (draft, action) => {
  if (!draft.labelOptions) return

  draft.selectedCollectionDate = action.payload.collectionDate
  draft.selectedCollectionTime = action.payload.collectionTime
}

const getLabelOptionsRequest: CaseReducer<State, PayloadAction<{ shipmentId: number }>> = draft => {
  draft.ui.labelOptions.uiState = UiState.Pending
}

const getLabelOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{ labelOptions: LabelOptionsModel }>
> = (draft, action) => {
  const { labelOptions } = action.payload

  draft.labelOptions = labelOptions
  draft.ui.labelOptions.uiState = UiState.Success
}

const getLabelOptionsFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.labelOptions.uiState = UiState.Failure
  draft.ui.labelOptions.error = error
}

const clearLabelOptions: CaseReducer<State> = draft => {
  draft.labelOptions = undefined
}

const getShippingOrderPackageSizesRequest: CaseReducer<
  State,
  PayloadAction<{
    shippingOrderId: number
    shipmentId: number
  }>
> = draft => {
  draft.ui.shippingOrderPackageSizes.uiState = UiState.Pending
}

const getShippingOrderPackageSizesSuccess: CaseReducer<
  State,
  PayloadAction<ShippingOrderPackageSizesModel>
> = (draft, action) => {
  const { packageSizes, packageSizeSelections } = action.payload

  draft.shippingOrderPackageSizes = { packageSizes, packageSizeSelections }

  draft.ui.shippingOrderPackageSizes.uiState = UiState.Success
  draft.ui.shippingOrderPackageSizes.error = null
}

const getShippingOrderPackageSizesFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.shippingOrderPackageSizes.uiState = UiState.Failure
  draft.ui.shippingOrderPackageSizes.error = error
}

const setSelectedPackageSize: CaseReducer<
  State,
  PayloadAction<{ packageSizeId: number | null }>
> = (draft, action) => {
  draft.ui.shippingOrderPackageSizes.selectedPackageSizeId = action.payload.packageSizeId
}

const setSubmittedSelectedPackageSize: CaseReducer<
  State,
  PayloadAction<{ packageSizeId: number }>
> = (draft, action) => {
  draft.ui.shippingOrderPackageSizes.submittedSelectedPackageSizeId = action.payload.packageSizeId
}

const setIsPackageSizeValidationVisible: CaseReducer<
  State,
  PayloadAction<{ isValidationVisible: boolean }>
> = (draft, action) => {
  draft.ui.shippingOrderPackageSizes.isPackageSizeValidationVisible =
    action.payload.isValidationVisible
}

const clearPackageSizeSelection: CaseReducer<State, PayloadAction> = draft => {
  draft.ui.shippingOrderPackageSizes.selectedPackageSizeId =
    draft.shippingOrderPackageSizes?.packageSizeSelections.selectedPackageSizeId || null
  draft.ui.shippingOrderPackageSizes.submittedSelectedPackageSizeId = null
  draft.ui.shippingOrderPackageSizes.isPackageSizeValidationVisible = false
}

const setShippingContactNumber = (
  draft: State,
  action: PayloadAction<{ phoneNumber: string | null }>,
) => {
  draft.transaction!.buyerPhoneNumber = action.payload.phoneNumber
  draft.transaction!.sellerPhoneNumber = action.payload.phoneNumber
}

const setShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.shippingContactErrors.push(error)
}

const removeShippingContactErrors: CaseReducer<State> = draft => {
  draft.shippingContactErrors = []
}

const removeShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.shippingContactErrors = draft.shippingContactErrors.filter(
    ({ field, value }) => field !== error.field && value !== error.value,
  )
}

const conversationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getConversationSuccess,
    getConversationUiFailure,
    getConversationUiPending,
    clearConversation,
    setConversation,
    getComplaintSuccess,
    setIsSidebarOpen,
    getShipmentInstructionsRequest,
    getShipmentInstructionsSuccess,
    getShipmentInstructionsFailure,
    postReplyRequest,
    postReplyFailure: setSendMessageUiFailure,
    postReplySuccess,
    setRecipientId,
    createConversationThreadRequest,
    createConversationThreadFailure: setSendMessageUiFailure,
    enterTrackingCodeRequest,
    enterTrackingCodeSuccess,
    enterTrackingCodeUiFailure,
    deleteEnterTrackingCodeError,
    setSelectedDropOffTypeKey,
    clearDropOffTypeSelection,
    deleteDropOffTypeSelectionError,
    createOfferRequest,
    createOfferSuccess,
    createOfferFailure,
    acceptOfferRequest,
    acceptOfferSuccess,
    acceptOfferFailure,
    rejectOfferRequest,
    rejectOfferSuccess,
    rejectOfferFailure,
    setSelectedOfferPriceSuggestionOption,
    resetSelectedOfferPriceSuggestionOption,
    setOfferRequestSessionId,
    setMessageDeleted,
    setMessageLiked,
    setMessageUnliked,
    setActiveGenerateLabelModal,
    setHarassmentMessage,
    clearHarassmentMessage,
    setHarassmentWarningModalClosed,
    setEmailSharingModalVisible,
    setSelectedLabelType,
    clearDigitalLabelTypeSelection,
    getOfferRequestOptionsRequest,
    getOfferRequestOptionsSuccess,
    getOfferRequestOptionsFailure,
    createItemConversationThreadRequest,
    createItemConversationThreadSuccess,
    createItemConversationThreadFailure,
    getReturnShipmentOptionSuccess,
    fetchTransactionRequest,
    fetchTransactionRequestSuccess,
    fetchTransactionRequestFailure,
    setFirstTimeListerEducationModalVisible,
    clearShipmentInstructions,
    getCollectionDatesRequest,
    getCollectionDatesSuccess,
    getCollectionDatesFailure,
    setSelectedCollectionDate,
    setSelectedCollectionTime,
    clearSelectedCollection,
    confirmCollectionDateSelection,
    getLabelOptionsRequest,
    getLabelOptionsSuccess,
    getLabelOptionsFailure,
    clearLabelOptions,
    getShippingOrderPackageSizesRequest,
    getShippingOrderPackageSizesSuccess,
    getShippingOrderPackageSizesFailure,
    setSelectedPackageSize,
    setSubmittedSelectedPackageSize,
    setIsPackageSizeValidationVisible,
    clearPackageSizeSelection,
    setShippingContactNumber,
    setShippingContactError,
    removeShippingContactErrors,
    removeShippingContactError,
  },
})

export const { actions } = conversationSlice
export const plug = { [stateName]: conversationSlice.reducer }
export default conversationSlice.reducer
