import { createSelector, Selector } from 'reselect'

import { ItemDto } from 'types/dtos'

import { stateName, ItemUiView } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getSimilarItemsState = (state: AllState) => localState(state)[ItemUiView.SimilarItems]

export const getSimilarItemsIds = createSelector(getSimilarItemsState, state => state.ids)

export const getSimilarItemsById = createSelector(getSimilarItemsState, state => state.byId)

export const getSimilarItemsUiState = createSelector(getSimilarItemsState, state => state.uiState)

export const getCatalogItemState = (state: AllState) => localState(state)[ItemUiView.CatalogItems]

export const getCatalogItemCurrentPage = createSelector(
  getCatalogItemState,
  state => state.currentPage,
)

export const getCatalogItemEndReached = createSelector(
  getCatalogItemState,
  state => state.endReached,
)

export const getCatalogItemPerPage = createSelector(getCatalogItemState, state => state.perPage)

export const getCatalogItemTime = createSelector(getCatalogItemState, state => state.time)

export const getCatalogItemTotalEntries = createSelector(
  getCatalogItemState,
  state => state.totalEntries,
)

export const getCatalogItemErrors = createSelector(getCatalogItemState, state => state.errors)

export const getItemsFactory = <T = AllState, P = ItemDto>(
  idSelector: Selector<T, Array<number>>,
  byIdSelector: Selector<AllState, Record<number, P>>,
) =>
  createSelector(byIdSelector, idSelector, (items, ids) =>
    Object.keys(items).length ? ids.map(id => items[id]).filter(Boolean) : [],
  )

export const getSimilarItems = getItemsFactory(getSimilarItemsIds, getSimilarItemsById)
