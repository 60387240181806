// Value format should match this list https://en.wikipedia.org/wiki/List_of_HTTP_header_fields#Standard_request_fields
export const HEADERS = {
  ACCEPT_LANGUAGE: 'Accept-Language',
  COOKIE: 'Cookie',
  SET_COOKIE: 'Set-Cookie',
  USER_AGENT: 'User-Agent',
  X_ANON_ID: 'X-Anon-Id',
  X_CSRF_TOKEN: 'X-CSRF-Token',
  X_FORWARDED_FOR: 'X-Forwarded-For',
  X_FORWARDED_HOST: 'X-Forwarded-Host',
  X_FORWARDED_PROTO: 'X-Forwarded-Proto',
  X_REQUEST_ID: 'X-Request-ID',
  X_VINTED_IN_APP: 'X-Vinted-In-App',
  X_V_UDT: 'X-V-UDT',
  X_INVOKE_OUTPUT: 'X-Invoke-Output',
  X_NEXT_APP: 'X-Next-App',
  X_NONCE_AUTHENTICATION: 'X-Nonce-Authentication',
  X_DATADOME: 'X-Datadome',
  X_DATADOME_CID: 'X-Datadome-Cid',
  X_DD_B: 'X-Dd-B',
  PRAGMA: 'Pragma',
  CACHE_CONTROL: 'Cache-Control',
  CONTENT_TYPE: 'Content-Type',
  X_USER_LOCALE: 'X-User-Locale',
  X_FORCED_LOCALE: 'X-Forced-Locale',
} as const

export const NEXT_HEADERS = Object.fromEntries(
  Object.entries(HEADERS).map(([key, value]) => [key, value.toLowerCase()]),
) as {
  [K in keyof typeof HEADERS]: Lowercase<(typeof HEADERS)[K]>
}

export const SRE_BLACKBOX_PROBE_USER_AGENT = 'Vinted blackbox exporter'
