'use client'

import { useEffect, useMemo } from 'react'
import type { AppProps } from 'next/app'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { AuthzProvider } from '@vinted/authz-web'

import { BreakpointProvider } from 'components/Breakpoint'
import ErrorBoundary from 'components/ErrorBoundary'
import BrazeProvider from 'libs/common/braze/containers/BrazeProvider'
import NextRequestProvider from 'containers/RequestProvider/NextRequestProvider'
import TrackingProvider from 'containers/TrackingProvider'
import CookieManagerProvider from 'libs/common/cookie-manager/cookie-manager-provider'
import SystemConfigurationProvider from 'contexts/SystemConfigurationProvider'

import clientSideMetrics from 'libs/common/client-side-metrics'
import appHealth from 'libs/common/app-health/app-health'
import { serverSide } from 'libs/utils/environment'
import { getLocale, getMessages } from 'state/intl/selectors'
import { createCookieManager } from 'libs/common/cookie-manager/helpers'
import AuthModalProvider from 'libs/common/auth-modal/AuthModalProvider'
import DataDomeProvider from 'libs/common/datadome/containers/DataDomeProvider'
import { getIsWebView } from 'state/session/selectors'
import AbTestsProvider from 'contexts/AbTestsProvider'
import FeatureSwitchesProvider from 'contexts/FeatureSwitchesProvider'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import SessionProvider from 'contexts/SessionProvider'
import EnvsProvider from 'contexts/EnvsProvider'
import { VINTED_LOCALE_TO_ISO_MAP } from 'constants/language'
import UserStatsProvider from 'contexts/UserStatsProvider'

import CustomErrorComponent from './_error'
import BottomScripts from '../components/BottomScripts'
import CanaryToken from '../components/CanaryToken'
import ConsentBannerScript from '../components/ConsentBannerScript'
import GoogleTagManager from '../components/GoogleTagManager'
import AppHead from '../components/Head/AppHead'
import TrackRerender from '../components/TrackRerender'
import TrackScreen from '../components/TrackScreen'
import TrackWebVitals from '../components/TrackWebVitals'
import DataDomeScript from '../components/DataDomeScript'
import { AppProps as CustomAppProps } from '../libs/server-utils/ssr'

import { wrapper } from '../state/store'

import 'startup/intlPolyfills'
import './app.scss'

if (!serverSide && process.env.NODE_ENV === 'production') {
  const PRODUCTION_ENV = 'production'
  const SANDBOX_ENV = 'sandbox'

  // TODO: also check if run in local env to avoid triggering production logs locally
  const version = process.env.NEXT_PUBLIC_RELEASE_VERSION || 'unknown'
  const env = window.location.host.includes(SANDBOX_ENV) ? SANDBOX_ENV : PRODUCTION_ENV
  const ddProxyUrl = 'https://rum-collector.svc.vinted.com/'

  appHealth.initialize(process.env.NEXT_PUBLIC_SERVICE_CLIENT_NAME || 'core-next', version, env)
  datadogRum.init({
    applicationId:
      process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || 'b90722a0-1c29-48b4-af9c-34fffb86338b',
    clientToken:
      process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || 'pub9fd180c090593448102410bf135f044e',
    site: 'datadoghq.eu',
    proxy: ddProxyUrl,
    traceSampleRate: 0,
    // Sampling is done in our proxy server. That is because we want to have 100% sampling for custom metrics
    // This covers the % of sessions that will be sent to our proxy server.
    // We want to have 100% because we use proxy server for client-side metrics, which should have 100% sampling
    // sessionReplaySampleRate is used to indicate the % of sessions that should be sent from the proxy server to Datadog
    sessionSampleRate: env === PRODUCTION_ENV ? 50 : 100,
    // This is used by our proxy server to determine what % of sessions should be sent to Datadog
    // Note that based on our contract we pay less for sessions with replay than for sessions without replay below a certain threshold
    sessionReplaySampleRate: env === PRODUCTION_ENV ? 5 : 100,
    compressIntakeRequests: env === SANDBOX_ENV,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    service: process.env.NEXT_PUBLIC_SERVICE_CLIENT_NAME || 'core-next',
    env,
    version,
    allowedTracingUrls: [
      url => url.startsWith(window.location.origin) && !url.endsWith('[object%20Request]'),
    ],
  })

  datadogRum.setGlobalContextProperty('imageTag', process.env.NEXT_PUBLIC_IMG_TAG)
}

type Props = {
  _app: CustomAppProps
}

const ConditionalAuthzProvider = ({ children, permissions }) => {
  const isAuthzEnabled = useFeatureSwitch('next_authz')

  if (isAuthzEnabled) {
    return <AuthzProvider permissions={permissions}>{children}</AuthzProvider>
  }

  return children
}

const App = ({ Component, pageProps, router }: AppProps<Partial<Props>>) => {
  const { _app: appProps, ...componentProps } = pageProps

  const localeFromRedux = useSelector(getLocale)
  const messagesFromRedux = useSelector(getMessages)
  const isWebview = useSelector(getIsWebView)
  const cookieManager = useMemo(() => createCookieManager(appProps?.cookies), [appProps?.cookies])

  appHealth.disableForTestUsers(appProps?.sessionData.user?.email)

  useEffect(() => {
    datadogRum.setGlobalContextProperty('page', router.pathname)
    clientSideMetrics.counter('page_load', { page: router.pathname }).increment()
  }, [router.pathname])

  function renderCanaryToken() {
    if (process.env.NODE_ENV !== 'production') return null

    return (
      <ErrorBoundary>
        <CanaryToken />
      </ErrorBoundary>
    )
  }

  function renderPageWithoutContext() {
    return (
      <div className="next-page">
        <AppHead />
        <Component {...componentProps} />
        {renderCanaryToken()}
      </div>
    )
  }

  if (['/500', '/404', '/_error'].includes(router.pathname)) {
    return renderPageWithoutContext()
  }

  if (!appProps) {
    if (Component === CustomErrorComponent) return renderPageWithoutContext()

    throw new Error('Page is rendered without layout wrapper')
  }

  const isIntlSliceRefactorEnabled = !!appProps?.featureSwitches.find(
    featureSwitch => featureSwitch.name === 'intl_slice_refactor',
  )?.enabled
  const localeFromProps = VINTED_LOCALE_TO_ISO_MAP[appProps.locale] || appProps.locale

  // needs to be destructured because LinkifiedMessage component tries to add a property, which causes a type error:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cant_define_property_object_not_extensible
  const translationMessagesFromProps = { ...appProps.translationMessages }

  return (
    <>
      <AppHead url={appProps.url} />
      <FeatureSwitchesProvider featureSwitches={appProps.featureSwitches}>
        <EnvsProvider envs={appProps.envs}>
          <SystemConfigurationProvider configuration={appProps.systemConfiguration}>
            <SessionProvider initialSessionData={appProps.sessionData}>
              <ConditionalAuthzProvider permissions={appProps.permissions}>
                <AbTestsProvider initialAbTests={appProps.abTests}>
                  <IntlProvider
                    locale={isIntlSliceRefactorEnabled ? localeFromProps : localeFromRedux}
                    messages={
                      isIntlSliceRefactorEnabled ? translationMessagesFromProps : messagesFromRedux
                    }
                  >
                    <UserStatsProvider>
                      <ErrorBoundary
                        FallbackComponent={ErrorBoundary.AppError}
                        pathname={router.pathname}
                        shouldIncrementCounter
                      >
                        <CookieManagerProvider cookieManager={cookieManager}>
                          <TrackingProvider>
                            <NextRequestProvider
                              locationUrl={appProps.url}
                              pageId={appProps.pageId}
                              userAgent={appProps.userAgent}
                            >
                              <BrazeProvider>
                                <TrackScreen>
                                  <BreakpointProvider
                                    ssrConfigs={{
                                      device: appProps.device,
                                      isBot: appProps.isBot,
                                      viewportSize: appProps.viewportSize,
                                      isWebview,
                                    }}
                                  >
                                    <AuthModalProvider>
                                      <DataDomeProvider>
                                        <TrackWebVitals />
                                        <TrackRerender
                                          viewportSize={appProps.viewportSize}
                                          device={appProps.device}
                                          isBot={appProps.isBot}
                                          isWebview={isWebview}
                                        />
                                        <div className="next-page">
                                          <Component {...componentProps} />
                                          {renderCanaryToken()}
                                        </div>
                                        <GoogleTagManager />
                                        <ConsentBannerScript />
                                        <BottomScripts />
                                        <DataDomeScript />
                                      </DataDomeProvider>
                                    </AuthModalProvider>
                                  </BreakpointProvider>
                                </TrackScreen>
                              </BrazeProvider>
                            </NextRequestProvider>
                          </TrackingProvider>
                        </CookieManagerProvider>
                      </ErrorBoundary>
                    </UserStatsProvider>
                  </IntlProvider>
                </AbTestsProvider>
              </ConditionalAuthzProvider>
            </SessionProvider>
          </SystemConfigurationProvider>
        </EnvsProvider>
      </FeatureSwitchesProvider>
    </>
  )
}

const AppWithStore = wrapper.withRedux(App)

export default AppWithStore
