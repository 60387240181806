import { createAction } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import { FieldErrorType } from 'pages/ItemUpload/types'
import { Screen } from 'constants/tracking/screens'
import { ItemAfterUploadActions } from 'constants/item-upload'
import { ItemDto } from 'types/dtos'

import { FieldName, ItemStatus, stateName } from './constants'

export const fetchItemOfflineVerificationEligibility = createAction(
  `${stateName}/fetchItemOfflineVerificationEligibility`,
)

export const requestItemAuthenticityModal = createAction(
  `${stateName}/requestItemAuthenticityModal`,
  ({
    force = false,
    modalDataOnly = false,
  }: { force?: boolean; modalDataOnly?: boolean } = {}) => ({
    payload: {
      force,
      modalDataOnly,
    },
  }),
)

export const fetchBrands = createAction(
  `${stateName}/fetchBrands`,
  ({
    keyword = '',
    includeAllBrands = false,
  }: {
    keyword?: string
    includeAllBrands?: boolean
  }) => ({
    payload: {
      keyword,
      includeAllBrands,
    },
  }),
)

export const fetchShippingOptionsRequest = createAction<{
  isIVSEligible: boolean | undefined
  isEVSEligible: boolean | undefined
}>(`${stateName}/fetchShippingOptionsRequest`)

export const fetchPriceSuggestions = createAction(`${stateName}/fetchPriceSuggestions`)

export const fetchItemRequest = createAction<{
  id: number | null
  tempUuid: string
  userId: number
  setItemStatus: Dispatch<SetStateAction<ItemStatus>>
  removeFieldError: (fieldName: FieldName) => void
}>(`${stateName}/fetchItemRequest`)

export const fetchPhotoTips = createAction(`${stateName}/fetchPhotoTips`)

type SubmitItemActionArgs = {
  tempUuid: string
  itemStatus: ItemStatus
  screenName: Screen
  saveAsDraft: boolean
  skipPackageSizeValidation: boolean
  additionalHeaders: { [key: string]: string }
  isItemBumped: boolean
  trackListingToGoogleTagManager: (item: ItemDto) => void
  trackListingToBraze: (item: ItemDto) => void
  setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
  handleReplicaProof: (actions: Array<ItemAfterUploadActions> | undefined) => void
  showIVSModal: () => void
  showEVSModal: () => void
}

export const submitItem = createAction(
  `${stateName}/submitItem`,
  ({
    tempUuid,
    itemStatus,
    screenName,
    saveAsDraft,
    skipPackageSizeValidation,
    isItemBumped,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    handleReplicaProof,
    showIVSModal,
    showEVSModal,
  }: SubmitItemActionArgs) => ({
    payload: {
      tempUuid,
      itemStatus,
      screenName,
      skipPackageSizeValidation,
      additionalHeaders,
      trackListingToGoogleTagManager,
      trackListingToBraze,
      setFieldErrors,
      handleReplicaProof,
      showIVSModal,
      showEVSModal,
    },
    meta: {
      saveAsDraft,
      isItemBumped,
    },
  }),
)

export const selectCatalog = createAction<{
  catalogId: number
  itemStatus: ItemStatus
  fieldErrors: FieldErrorType
  setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
  removeFieldError: (fieldName: FieldName) => void
}>(`${stateName}/selectCatalog`)

export const fetchCatalogs = createAction(`${stateName}/fetchCatalogs`)
export const fetchSizeGroupsByCatalog = createAction(`${stateName}/fetchSizeGroupsByCatalog`)

export const fetchPackageSizesRequest = createAction(`${stateName}/fetchPackageSizesRequest`)

export const getDropOffLocationPrompt = createAction<{ userId: number }>(
  `${stateName}/getDropOffLocationPrompt`,
)
